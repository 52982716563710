<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto text-left" max-width="500">
          <v-card-title>{{app}}</v-card-title>
          <v-card-text>
            <v-row v-for="server in Object.keys($api.servers)" :key="server">
              <v-col>{{server}}</v-col>
              <v-col>{{$api.servers[server]}}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    app:null,
  }),
  created() {
    this.app = process.env.VUE_APP_URL
  },
};
</script>
